<template>
  <div class="antd-table-search-wrap">
    <a-list item-layout="horizontal" :data-source="listData" :pagination="pagination">
      <a-list-item slot="renderItem" slot-scope="item">
        <div class="list">
          <div class="list-box" @click="listBtn(item)" style="">
            <a-icon type="calendar" style="padding-right:5px;" />{{item.policyType}}
          </div>
        </div>
      </a-list-item>
    </a-list>
  </div>
</template>

<script>
import { getDeclareList } from "@/httpsAPI/declare/index";


export default {
  data () {
    return {
      listData: [],
      pagination: {
        total: 0,
        current: 1,
        onChange: page => {
          this.pagination.current = page
          this.loadDeclareeData()
        },
        pageSize: 8,
      },
    }
  },
  created () {
    this.loadDeclareeData()
  },
  methods: {
    //列表
    loadDeclareeData () {
      return getDeclareList(Object.assign({ pageNo: this.pagination.current, pageSize: this.pagination.pageSize }, this.queryParams)).then(res => {
        this.listData = res.data.data
        this.pagination.total = res.data.totalCount
      })
    },
    //详情
    listBtn (item) {
      console.log(item,"item")
      this.$router.push({path:"/declare/detail",query:{id:item.id,policyType:item.policyType}})
    }
  }

}
</script>

<style lang="less" scoped>
.list-box {
  cursor: pointer;
  float: left;
  width: 250px;
  height: 150px;
  line-height: 150px;
  text-align: center;
  font-size: 18px;
  font-weight: 600;
  color: #1890ff;
  background: #fff;
  box-shadow: 0 0 20px -5px #1890ff;
  border-radius: 8px;
  margin: 30px;
}
.antd-table-search-wrap {
  height: 100%;
}
.ant-list-split .ant-list-item {
  border: none;
}
.ant-list-something-after-last-item
  .ant-spin-container
  > .ant-list-items
  > .ant-list-item:last-child {
  border: none;
}
.ant-spin-container ul li {
  float: left;
}
/deep/ .ant-list-pagination {
  clear: both;
}
</style>