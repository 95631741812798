import API from "@/httpsAPI/API";
import request from "@/utils/request";

export function getDeclareList (params) {
  return request({
    url: "/policy/PolicyType/list",
    method: 'post',
    data: params
  })
}

export function getDeclareDetail (params) {
  return request({
    url: "/policy/PolicyApply/listPolicyApply",
    method: 'post',
    data: params
  })
}